<template>
  <a
    :href="$host() + '/'"
    class="te-navbar-brand h-full"
  >
    <p class="te-copy-word mb-0 block text-left text-black">
      現役エンジニアから学ぶなら
    </p>
    <img
      class="h-auto max-w-full"
      src="~/assets/images/Te/TeCommon/Logo/logo-blue.svg"
      alt="テックアカデミー"
      width="300"
      height="39"
    >
  </a>
</template>

<style scoped>
/* デザインパターン

〜374px
ロゴ横幅168px
キャッチコピー文字サイズ8px

1200px〜
ロゴ横幅200px
キャッチコピー文字サイズ10px */

.te-navbar-brand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10.5rem;
  margin-right: 1rem;
  @media (min-width: 1200px) {
    width: 12.5rem;
  }
}

/* キャッチコピーテキスト */
.te-copy-word {
  padding-bottom: 0.125rem;
  margin-left: 1.5rem;
  font-size: 0.625rem;
  line-height: 1;
  transform: scale(0.8);
  @media (min-width: 1200px) {
    margin-left: 2.8125rem;
    transform: scale(1);
  }
}
</style>
